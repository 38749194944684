<template>
  <div class="view-contanier">
    <div class="opt">
      <BaseFilter :isActive="isActive" :width="466" @sendFilter="sendFilter" @reset="reset">
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="70px">
            <!-- <el-form-item label="搜索">
              <el-input
                v-model="filterList.companyIdentityCode"
                placeholder="请输入公司代码/名称"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="客户代码">
              <el-select
                v-model="filterList.companyIdentityCode"
                filterable
                placeholder="请选择状态"
                style="width: 320px"
              >
                <el-option
                  v-for="item in companyOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <!-- 客户编码 -->
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        :isPager="true"
        @handlePageChange="handlePageChange"
        @sortChange="sortChange"
      >
        <template slot="companyName" slot-scope="row">
          <div class="company">{{ row.scope.companyName }}</div>
        </template>
        <template slot="unconfirmedAmount" slot-scope="row">
          <div class="unAmount">
            {{ row.scope.unconfirmedAmount }}
            <img src="@/assets/images/order/cut.png" title="费用查询" @click="feeSeach('FYCX')">
          </div>
        </template>
        <template slot="isActived" slot-scope="row">
          <div class="typebox">
            <div :class="row.scope.isActived ? 'bod' : 'bod red'"></div>
            <div>{{ row.scope.isActived ? "启用" : "停用" }}</div>
          </div>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="tableopt">
            <span @click="updateRow(row.scope)">修改</span>
            <span @click="updateLog(row.scope)">修改日志</span>
            <span @click="accountLog(row.scope)">账户流水</span>
          </div>
        </template>
      </BaseTable>
    </div>
    <AccountDialog
      :dialogFormVisible="dialogFormVisible"
      :editData="editData"
      @ok="ok"
      @cancel="cancel"
    ></AccountDialog>
    <account-update-log-drawer
      :drawer="drawer"
      :companyId="editData.companyId"
      @close="close"
    ></account-update-log-drawer
    ><account-log-drawer
      :drawer="drawer1"
      :companyId="editData.companyId"
      @close="close1"
    ></account-log-drawer>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const AccountDialog = () => import('./components/AccountDialog')
const BaseFilter = () => import('@/components/filter/BaseFilter')

import {
  token,
  getSysParams,
  getCompanyDrapdown
} from '@/api/system'
import { getPages } from '@/api/client'
import AccountUpdateLogDrawer from './components/AccountUpdateLogDrawer.vue'
import AccountLogDrawer from './components/AccountLogDrawer.vue'
import { getRouteByCode } from '@/utils/common'
import { myMixins } from '@/mixins'
export default {
  name: 'AccountCustom',
  components: {
    BaseTable,
    AccountDialog,
    BaseFilter,
    AccountUpdateLogDrawer,
    AccountLogDrawer
  },
  mixins: [myMixins],
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '客户',
            width: '300',
            // isSort: true, // 是否支持过滤
            fixed: 'left', // 是否固定 true, left, right
            isCustom: true // 是否自定义列显示
          },
          {
            filed: 'currentAmount',
            label: '当前余额',
            width: '120'
          },
          {
            filed: 'paymentAmount',
            label: '充值总额',
            width: '100'
          },
          {
            filed: 'creditAmount',
            label: '信用额度',
            width: '120'
          },
          {
            filed: 'deductionAmount',
            label: '扣款总额',
            width: '120'
          },
          {
            filed: 'unconfirmedAmount',
            label: '未确认金额',
            width: '150',
            isCustom: true
          },
          {
            filed: 'lowestLimit',
            label: '最低余额标准',
            width: '102'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '120'
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '120',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: '150'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '280',
            fixed: 'right', // 是否固定 true, left, right
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      filterList: {
        companyIdentityCode: ''
      },
      isActive: false,
      dialogFormVisible: false,
      editData: {},
      drawer: false,
      drawer1: false,
      activeOption: [
        {
          value: true,
          label: '启用'
        },
        {
          value: false,
          label: '停用'
        }
      ],
      companyOption: [],
      systemCode: '',
      orderObj: {}
    }
  },
  async created() {
    try {
      const res = await getSysParams({ type: 14 })
      if (res.success) {
        this.systemCode = res.data[0].value[0].dbValue
      }
      const res1 = await getCompanyDrapdown()
      if (res1.success) {
        const arr = res1.data.map((item) => {
          return {
            value: item.identityCode,
            label: `${item.code}-${item.wbName}`
          }
        })
        this.companyOption = arr
      }
      this.initList()
    } catch (error) {
      console.log(error)
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    ok() {
      this.cancel()
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    close() {
      this.editData = {}
      this.drawer = false
    },
    close1() {
      this.editData = {}
      this.drawer1 = false
    },
    cancel() {
      this.editData = {}
      this.dialogFormVisible = false
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.orderObj = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.orderObj = {}
      }
      this.getList(this.pagerConfig)
    },
    getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.orderObj,
            ...this.formatterFilter(),
            supplierCode: this.systemCode
          }
        }
        getPages(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.columns = res.data.list
            this.tableData.total = res.data.total
          } else {
            this.loading = false
            if (res.data.code === 50001) {
              token({
                source: 1
              }).then((res1) => {
                if (res1.success) {
                  this.getList(params)
                } else {
                  this.$message.error(res1.errorMessage)
                }
              })
            } else {
              this.$message.error(res.errorMessage)
            }
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    updateRow(row) {
      this.editData = row
      this.dialogFormVisible = true
    },
    updateLog(row) {
      this.editData = row
      this.drawer = true
    },
    accountLog(row) {
      this.editData = row
      this.drawer1 = true
    },
    initList() {
      const params = {
        ...this.pager
      }
      this.getList(params)
    },
    sendFilter() {
      if (this.filterList.companyIdentityCode !== '') {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    reset() {
      this.filterList = {
        companyIdentityCode: ''
      }
      this.isActive = false
    },
    formatterFilter() {
      return {
        companyIdentityCode: this.filterList.companyIdentityCode
          ? this.filterList.companyIdentityCode
          : undefined
      }
    },
    feeSeach(menuCode = 'FYCX') {
      // openUrl({ menuCode, id: scheduleId, queryJson, type }) {
      const path = getRouteByCode(menuCode)
      // console.log(path, menuCode, btnCode)
      const params = { menuCode, queryJson: JSON.stringify({ 'status': 1 }) }
      this.$store.commit('SET_CONNNET', params)
      if (path !== this.$route.fullPath) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #eeeeee;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .tableopt {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-right: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
    }
  }
  .company {
    text-align: left;
  }
  .unAmount {
    color: #2395F3;
    img {
      cursor: pointer;
    }
  }
  .typebox {
    display: flex;
    justify-content: center;
    align-items: center;
    .bod {
      width: 6px;
      height: 6px;
      background: #35ba83;
      opacity: 1;
      border-radius: 50%;
      margin-right: 5px;
    }
    .red {
      width: 6px;
      height: 6px;
      background: #fe453d;
      opacity: 1;
    }
  }

  .table-opt:deep(.el-button) {
    padding: 0;
  }
}
</style>
