import http from './http'

import config from '@/utils/config'

const CompanyAccount = `/backEnd/api/${config.supplyCode}/CompanyAccount`

function getPages(params) {
  return http.post(`${CompanyAccount}/getPages`, params)
}
function companyUpdate(params) {
  return http.post(`${CompanyAccount}/update`, params)
}
function getLogPages(params) {
  return http.post(`${CompanyAccount}/getLogPages`, params)
}
function getChangeRecordPages(params) {
  return http.post(`${CompanyAccount}/getChangeRecordPages`, params)
}
function disable(params) {
  return http.put(`${CompanyAccount}/disable`, params)
}
function enable(params) {
  return http.put(`${CompanyAccount}/enable`, params)
}
const payment = `/backEnd/api/${config.supplyCode}/payment`

function getPaymentPagesList(params) {
    return http.post(`${payment}/getPaymentPagesList`, params)
}
function getPaymentLogs(params) {
  return http.get(`${payment}/getPaymentLogs`, params)
}
function approval(params) {
  return http.post(`${payment}/approval`, params)
}
const quote = `/backEnd/api/${config.supplyCode}/Quote`

function quotesResult(params) {
    return http.get(`${quote}/quotesResult`, params)
}
export {
    getPages,
    companyUpdate,
    getLogPages,
    getChangeRecordPages,
    disable,
    enable,
    getPaymentPagesList,
    getPaymentLogs,
    approval,
    quotesResult
}
