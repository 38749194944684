<template>
  <Drawer :drawer="drawer" title="修改日志" :size="720" @close="handleClose">
    <div class="log-body">
      <BaseTable
        :height="height"
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange">
        <template slot="index" slot-scope="row">
          {{ tableData.columns.indexOf(row.scope) + 1 }}
        </template>
      </BaseTable>
    </div>
  </Drawer>
</template>

<script>
import { getChangeRecordPages } from '@/api/client'
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable.vue')
export default {
    components: { BaseTable, Drawer },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pagerConfig: {
        pageIndex: 1,
        pageSize: 50
      },
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'createdOn',
            label: '修改日期',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : ''
          },
          {
            filed: 'createdName',
            label: '修改人',
            width: '100px'
          },
          {
            filed: 'description',
            label: '内容'
          },
          {
            filed: 'reason',
            label: '原因'
          }
        ],
        columns: [],
        total: 0
      },
      height: 0
    }
  },
  watch: {
    async drawer(val) {
      if (val) {
        await this.getLists(this.pagerConfig)
        this.$nextTick(() => {
          this.height = document.querySelector('.log-body').offsetHeight - 100
        })
      }
    }
  },
  methods: {
    getLists(param) {
      param = {
        ...param,
        queryParam: { companyId: this.companyId }
      }
      getChangeRecordPages(param).then((res) => {
        if (res.success) {
            this.tableData.columns = res.data.list
            this.tableData.total = res.data.total
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getLists(params)
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.log-body {
  width: calc(100% - 40px);
  height: 100%;
  margin: 20px auto 0;
}
</style>
