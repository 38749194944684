var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c(
        "div",
        { staticClass: "opt" },
        [
          _c(
            "BaseFilter",
            {
              attrs: { isActive: _vm.isActive, width: 466 },
              on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
            },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.filterList, "label-width": "70px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户代码" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择状态",
                              },
                              model: {
                                value: _vm.filterList.companyIdentityCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filterList,
                                    "companyIdentityCode",
                                    $$v
                                  )
                                },
                                expression: "filterList.companyIdentityCode",
                              },
                            },
                            _vm._l(_vm.companyOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                  isPager: true,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "companyName",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "company" }, [
                            _vm._v(_vm._s(row.scope.companyName)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "unconfirmedAmount",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "unAmount" }, [
                            _vm._v(
                              " " + _vm._s(row.scope.unconfirmedAmount) + " "
                            ),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/order/cut.png"),
                                title: "费用查询",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.feeSeach("FYCX")
                                },
                              },
                            }),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "typebox" }, [
                            _c("div", {
                              class: row.scope.isActived ? "bod" : "bod red",
                            }),
                            _c("div", [
                              _vm._v(
                                _vm._s(row.scope.isActived ? "启用" : "停用")
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "tableopt" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.updateRow(row.scope)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.updateLog(row.scope)
                                  },
                                },
                              },
                              [_vm._v("修改日志")]
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.accountLog(row.scope)
                                  },
                                },
                              },
                              [_vm._v("账户流水")]
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1912454817
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("AccountDialog", {
        attrs: {
          dialogFormVisible: _vm.dialogFormVisible,
          editData: _vm.editData,
        },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
      _c("account-update-log-drawer", {
        attrs: { drawer: _vm.drawer, companyId: _vm.editData.companyId },
        on: { close: _vm.close },
      }),
      _c("account-log-drawer", {
        attrs: { drawer: _vm.drawer1, companyId: _vm.editData.companyId },
        on: { close: _vm.close1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }