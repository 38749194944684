var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "流水日志", size: 980 },
      on: { close: _vm.handleClose },
    },
    [
      _c(
        "div",
        { staticClass: "log-body" },
        [
          _c("BaseTable", {
            attrs: {
              height: _vm.height,
              tableData: _vm.tableData,
              pageIndex: _vm.pagerConfig.pageIndex,
              pageSize: _vm.pagerConfig.pageSize,
              loading: _vm.loading,
            },
            on: {
              handlePageChange: _vm.handlePageChange,
              sortChange: _vm.sortChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tableData.columns.indexOf(row.scope) + 1) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }